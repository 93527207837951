// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-all-order-status-js": () => import("./../../../src/pages/all-order-status.js" /* webpackChunkName: "component---src-pages-all-order-status-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inventory-js": () => import("./../../../src/pages/inventory.js" /* webpackChunkName: "component---src-pages-inventory-js" */),
  "component---src-pages-order-complete-js": () => import("./../../../src/pages/order-complete.js" /* webpackChunkName: "component---src-pages-order-complete-js" */),
  "component---src-pages-order-cookie-js": () => import("./../../../src/pages/order-cookie.js" /* webpackChunkName: "component---src-pages-order-cookie-js" */),
  "component---src-pages-order-dry-toppings-js": () => import("./../../../src/pages/order-dry-toppings.js" /* webpackChunkName: "component---src-pages-order-dry-toppings-js" */),
  "component---src-pages-order-name-js": () => import("./../../../src/pages/order-name.js" /* webpackChunkName: "component---src-pages-order-name-js" */),
  "component---src-pages-order-qr-reader-js": () => import("./../../../src/pages/order-qr-reader.js" /* webpackChunkName: "component---src-pages-order-qr-reader-js" */),
  "component---src-pages-order-qr-sheet-writer-js": () => import("./../../../src/pages/order-qr-sheet-writer.js" /* webpackChunkName: "component---src-pages-order-qr-sheet-writer-js" */),
  "component---src-pages-order-qr-writer-js": () => import("./../../../src/pages/order-qr-writer.js" /* webpackChunkName: "component---src-pages-order-qr-writer-js" */),
  "component---src-pages-order-qr-zip-writer-js": () => import("./../../../src/pages/order-qr-zip-writer.js" /* webpackChunkName: "component---src-pages-order-qr-zip-writer-js" */),
  "component---src-pages-order-review-js": () => import("./../../../src/pages/order-review.js" /* webpackChunkName: "component---src-pages-order-review-js" */),
  "component---src-pages-order-status-js": () => import("./../../../src/pages/order-status.js" /* webpackChunkName: "component---src-pages-order-status-js" */),
  "component---src-pages-order-url-reader-js": () => import("./../../../src/pages/order-url-reader.js" /* webpackChunkName: "component---src-pages-order-url-reader-js" */),
  "component---src-pages-order-wet-toppings-js": () => import("./../../../src/pages/order-wet-toppings.js" /* webpackChunkName: "component---src-pages-order-wet-toppings-js" */)
}

