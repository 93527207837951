/* eslint eqeqeq: "off" */
import { createStore as reduxCreateStore, applyMiddleware, compose } from "redux"
import { createLogger } from "redux-logger"
import thunk from "redux-thunk"
import promise from "redux-promise-middleware"
import { persistStore, persistReducer } from "redux-persist"
import { rootReducer, CACHE_VERSION } from "./createReducer"
import storage from "redux-persist/lib/storage"
import { getDevTools } from "../util/EnvironmentUtil"

// Clear out cache if our version has changed.
if(typeof window !== 'undefined') {
  const item = JSON.parse(window.localStorage.getItem("persist:root"));
  if(item == null || item.cacheVersion != CACHE_VERSION) {
    window.localStorage.removeItem("persist:root")
  } 
}

const middleware = applyMiddleware(promise, thunk, createLogger());

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["inventory"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = reduxCreateStore(persistedReducer, compose(middleware, getDevTools()));
export const persistor = persistStore(store);