/* eslint eqeqeq: "off" */
const windowGlobal = typeof window != 'undefined' && window;
const touch = windowGlobal && windowGlobal.matchMedia && windowGlobal.matchMedia("(pointer: coarse)").matches;
const devtools =
  process.env.NODE_ENV == 'development' && windowGlobal.devToolsExtension
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f;

export const isClient = () => windowGlobal;
export const isSSR = () => !windowGlobal;
export const isTouch = () => touch;
export const getDevTools = () => devtools;
