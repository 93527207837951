/* eslint eqeqeq: "off" */
import { createOrder } from "../util/OrderUtil"

// Controls the version of our cache
export const CACHE_VERSION = 1.1;

const SET_ORDER = "SET_ORDER";
const RESET_ORDER = "RESET_ORDER";
const SET_CUSTOMER_NAME = "SET_CUSTOMER_NAME";
const SET_COOKIE = "SET_COOKIE";
const SET_DRY_TOPPINGS = "SET_DRY_TOPPINGS";
const ADD_DRY_TOPPING = "ADD_DRY_TOPPING";
const REMOVE_DRY_TOPPING = "REMOVE_DRY_TOPPING";
const TOGGLE_DRY_TOPPING = "TOGGLE_DRY_TOPPING";
const SET_WET_TOPPINGS = "SET_WET_TOPPINGS";
const ADD_WET_TOPPING = "ADD_WET_TOPPING";
const REMOVE_WET_TOPPING = "REMOVE_WET_TOPPING";
const TOGGLE_WET_TOPPING = "TOGGLE_WET_TOPPING";
const SET_ORDER_ID = "SET_ORDER_ID";
const SET_INVENTORY = "SET_INVENTORY";
const SET_INVENTORY_DRY_TOPPING = "SET_INVENTORY_DRY_TOPPING";
const SET_INVENTORY_WET_TOPPING = "SET_INVENTORY_WET_TOPPING";
const SET_INVENTORY_WET_TOPPING_COUNT = "SET_INVENTORY_WET_TOPPING_COUNT";
const SET_INVENTORY_COOKIE = "SET_INVENTORY_COOKIE";
const SET_FLOWING = "SET_FLOWING";
const SET_CAMERA_FACING_MODE = "SET_CAMERA_FACING_MODE";
const SUBMIT_ORDER = "SUBMIT_ORDER";
const SET_SUBMITTED_ORDER_IDS = "SET_SUBMITTED_ORDER_IDS";
const SET_QR_SHEET_PROPS = "SET_QR_SHEET_PROPS";
const SET_AVAILABLE_INGREDIENT_COUNTS = "SET_AVAILABLE_INGREDIENT_COUNTS";

const initialState = {
  cacheVersion: CACHE_VERSION,
  order: createOrder(),
  submittedOrderIds: [],
  recentName: null,
  inventory: {
    dryToppings: [...Array(40)],
    wetToppings: [...Array(2)],
    wetToppingCounts: [...Array(2)],
    cookies: [...Array(40)],
    flowing: {
      cookies: {

      },
      dryToppings: {

      },
      wetToppings: {

      },
    },
  },
  cameraFacingMode: "environment",
  qrSheetProps: {
    ppi: 300,
    rows: 10,
    cols: 10,
    qrSizeIn: 0.86,
    qrSpacingIn: 1.125,
  },
  availableIngredientCounts: {},
};

export const rootReducer = (state=initialState, action) => {
  const { type, payload } = action;
  const { order={}, submittedOrderIds=[] } = state;
  switch(type) {
    case SET_ORDER: {
      return {...state, order: payload.order };
    }
    case RESET_ORDER: {
      return {...state, order: initialState.order };
    }
    case SET_CUSTOMER_NAME: {
      const { customerName } = payload;
      return {...state, order: {...order, customerName}, recentName: customerName };
    }
    case SET_COOKIE: {
      const { cookie } = payload;
      return {...state, order: {...order, cookie} };
    }
    case SET_DRY_TOPPINGS: {
      const dryToppings = payload.dryToppings.map(({ name, extra }) => ({ name, extra }));
      return {...state, order: {...order, dryToppings} };
    }
    case ADD_DRY_TOPPING: {
      const { name, extra } = payload;
      const existingTopping = order.dryToppings.find(t => t.name == name);
      let dryToppings;
      if(existingTopping != null) {
        if(existingTopping.extra == extra) {
          return state;
        }
        dryToppings = order.dryToppings.filter(t => t.name != name);
      } else {
        dryToppings = [...order.dryToppings];
      }
      dryToppings.push({ name, extra });
      return {...state, order: {...order, dryToppings }};
    }
    case REMOVE_DRY_TOPPING: {
      const { name } = payload;
      const dryToppings = order.dryToppings.filter(t => t.name != name);
      if(order.dryToppings.length == dryToppings.length) {
        return state;
      }
      return {...state, order: {...order, dryToppings }};
    }
    case TOGGLE_DRY_TOPPING: {
      const { name, extra, modal } = payload;
      const existingTopping = order.dryToppings.find(t => t.name == name);
      let dryToppings;
      if(existingTopping != null) {
        dryToppings = order.dryToppings.filter(t => t.name != name);
        if(existingTopping.extra != extra) {
          dryToppings.push({ name, extra });
        }
      } else {
        if(modal) {
          dryToppings = [];
        } else {
          dryToppings = [...order.dryToppings];
        }
        dryToppings.push({ name: name, extra });
      }
      return {...state, order: {...order, dryToppings }};
    }
    case SET_WET_TOPPINGS: {
      const wetToppings = payload.wetToppings.map(({ name, extra }) => ({ name, extra }));
      return {...state, order: {...order, wetToppings} };
    }
    case ADD_WET_TOPPING: {
      const { name, extra } = payload;
      const existingTopping = order.wetToppings.find(t => t.name == name);
      let wetToppings;
      if(existingTopping != null) {
        if(existingTopping.extra == extra) {
          return state;
        }
        wetToppings = order.wetToppings.filter(t => t.name != name);
      } else {
        wetToppings = [...order.wetToppings];
      }
      wetToppings.push({ name, extra });
      return {...state, order: {...order, wetToppings }};
    }
    case REMOVE_WET_TOPPING: {
      const { name } = payload;
      const wetToppings = order.wetToppings.filter(t => t.name != name);
      if(order.wetToppings.length == wetToppings.length) {
        return state;
      }
      return {...state, order: {...order, wetToppings }};
    }
    case TOGGLE_WET_TOPPING: {
      const { name, extra, modal } = payload;
      const existingTopping = order.wetToppings.find(t => t.name == name);
      let wetToppings;
      if(existingTopping != null) {
        wetToppings = order.wetToppings.filter(t => t.name != name);
        if(existingTopping.extra != extra) {
          wetToppings.push({ name, extra });
        }
      } else {
        if(modal) {
          wetToppings = [];
        } else {
          wetToppings = [...order.wetToppings];
        }
        wetToppings.push({ name: name, extra });
      }
      return {...state, order: {...order, wetToppings }};
    }
    case SET_ORDER_ID: {
      const { orderId } = payload;
      return {...state, order: {...order, orderId} };
    }
    case SET_SUBMITTED_ORDER_IDS: {
      const { orderIds } = payload;
      const recordedIds = [];
      return {...state, submittedOrderIds: orderIds.filter(id => id != null).filter(id => {
        if(recordedIds.includes(id)) {
          return false;
        }
        recordedIds.push(id);
        return true;
      }) };
    }
    case SUBMIT_ORDER: {
      const updatedOrder = {...payload.order};
      const updatedOrderIds = [...submittedOrderIds, updatedOrder.orderId];
      return {...state, order: updatedOrder, submittedOrderIds: updatedOrderIds.filter(id => id != null) };
    }
    case SET_INVENTORY: {
      const { inventory } = payload;
      return {...state, inventory };
    }
    case SET_INVENTORY_DRY_TOPPING: {
      const { name, index } = payload;
      const inventory = {...state.inventory};
      inventory.dryToppings = [...inventory.dryToppings];
      inventory.dryToppings[index] = name;
      return {...state, inventory };
    }
    case SET_INVENTORY_WET_TOPPING: {
      const { name, index } = payload;
      const inventory = {...state.inventory};
      inventory.wetToppings = [...inventory.wetToppings];
      inventory.wetToppings[index] = name;
      return {...state, inventory };
    }
    case SET_INVENTORY_WET_TOPPING_COUNT: {
      const { count, index } = payload;
      const inventory = {...state.inventory};
      inventory.wetToppingCounts = [...inventory.wetToppingCounts];
      inventory.wetToppingCounts[index] = count;
      return {...state, inventory };
    }
    case SET_INVENTORY_COOKIE: {
      const { name, index } = payload;
      const inventory = {...state.inventory};
      inventory.cookies = [...inventory.cookies];
      if(Array.isArray(index)) {
        for(let idx of index) {
          inventory.cookies[idx] = name;
        }
      } else {
        inventory.cookies[index] = name;
      }
      return {...state, inventory };
    }
    case SET_FLOWING: {
      const { type, name, flowing } = payload;
      const inventory = {...state.inventory};
      if(inventory.flowing == null) {
        inventory.flowing = {};
      } else {
        inventory.flowing = {...inventory.flowing};
      }

      if(inventory.flowing[type] == null) {
        inventory.flowing[type] = {};
      } else {
        inventory.flowing[type] = {...inventory.flowing[type]};
      }

      inventory.flowing[type][name] = flowing;
      return {...state, inventory };
    }
    case SET_CAMERA_FACING_MODE: {
      const { cameraFacingMode } = payload;
      return {...state, cameraFacingMode };
    }
    case SET_QR_SHEET_PROPS: {
      const props = payload;
      const currProps = state.qrSheetProps || {};
      return {...state, qrSheetProps: {...currProps, ...props}};
    }
    case SET_AVAILABLE_INGREDIENT_COUNTS: {
      const availableIngredientCounts = payload;
      return {...state, availableIngredientCounts };
    }
    default: {
      return state;
    }
  }
};

export const mapStateToProps = storeState => ({
  order: storeState.order,
  submittedOrderIds: storeState.submittedOrderIds,
  recentName: storeState.recentName,
  inventory: storeState.inventory,
  cameraFacingMode: storeState.cameraFacingMode,
  qrSheetProps: storeState.qrSheetProps,
  availableIngredientCounts: storeState.availableIngredientCounts,
});

export const mapDispatchToProps = dispatch => ({
  setOrder: order => dispatch({ type: SET_ORDER, payload: { order } }),
  resetOrder: order => dispatch({ type: RESET_ORDER, payload: {} }),
  setCustomerName: customerName => dispatch({ type: SET_CUSTOMER_NAME, payload: { customerName } }),
  setCookie: cookie => dispatch({ type: SET_COOKIE, payload: { cookie } }),
  setDryToppings: dryToppings => dispatch({ type: SET_DRY_TOPPINGS, payload: { dryToppings } }),
  addDryTopping: ({ name, extra=false }) => dispatch({ type: ADD_DRY_TOPPING, payload: { name, extra } }),
  removeDryTopping: ({ name }) => dispatch({ type: REMOVE_DRY_TOPPING, payload: { name } }),
  toggleDryTopping: ({ name, extra=false }, modal) => dispatch({ type: TOGGLE_DRY_TOPPING, payload: { name, extra, modal } }),
  setWetToppings: wetToppings => dispatch({ type: SET_WET_TOPPINGS, payload: { wetToppings } }),
  addWetTopping: ({ name, extra=false }) => dispatch({ type: ADD_WET_TOPPING, payload: { name, extra } }),
  removeWetTopping: ({ name }) => dispatch({ type: REMOVE_WET_TOPPING, payload: { name } }),
  toggleWetTopping: ({ name, extra=false }, modal) => dispatch({ type: TOGGLE_WET_TOPPING, payload: { name, extra, modal } }),
  setOrderId: orderId => dispatch({ type: SET_ORDER_ID, payload: { orderId } }),
  setInventory: inventory => dispatch({ type: SET_INVENTORY, payload: { inventory } }),
  setInventoryDryTopping: ({ name, index }) => dispatch({ type: SET_INVENTORY_DRY_TOPPING, payload: { name, index } }),
  setInventoryWetTopping: ({ name, index }) => dispatch({ type: SET_INVENTORY_WET_TOPPING, payload: { name, index } }),
  setInventoryWetToppingCounts: ({ count, index }) => dispatch({ type: SET_INVENTORY_WET_TOPPING_COUNT, payload: { count, index } }),
  setInventoryCookie: ({ name, index }) => dispatch({ type: SET_INVENTORY_COOKIE, payload: { name, index } }),
  setFlowing: ({ type, name, flowing }) => dispatch({ type: SET_FLOWING, payload: { type, name, flowing } }),
  setCameraFacingMode: cameraFacingMode => dispatch({ type: SET_CAMERA_FACING_MODE, payload: { cameraFacingMode } }),
  setQrSheetProps: props => dispatch({ type: SET_QR_SHEET_PROPS, payload: props }),
  setAvailableIngredientCounts:  availableIngredientCounts => dispatch({ type: SET_AVAILABLE_INGREDIENT_COUNTS, payload: availableIngredientCounts }),
  setSubmittedOrderIds: ({ orderIds }) => dispatch({ type: SET_SUBMITTED_ORDER_IDS, payload: { orderIds } }),
  submitOrder: order => dispatch({ type: SUBMIT_ORDER, payload: { order } }),
});
