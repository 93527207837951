import { API } from "aws-amplify"
import { sanitize } from "./ApiUtil"

export const doGetOrder = (orderId, callback=null) => {
  const init = {
    queryStringParameters: { orderId },
  };
  return API.get("orders", "/orders", init)
    .then(response => {
      const order = sanitize(response, {});
      if(order != null) {
        // Make sure it has a toppings list
        if(order.dryToppings == null) {
          order.dryToppings = [];
        }
        if(order.wetToppings == null) {
          order.wetToppings = [];
        }
      }
      if(callback != null) {
        callback(order);
      }
    });
};

export const doPutOrder = (order, callback=null) => {
  const init = {
    body: { order },
  };
  return API.put("orders", "/orders", init)
    .then(response => {
      const status = sanitize(response, {});
      if(callback != null) {
        callback(status);
      }
      return status;
    });
};

export const doGenerateOrderId = (n=1, callback=null) => {
  const init = {
    queryStringParameters: { n },
    body: {},
  };
  return API.post("orders", "/generation", init)
    .then(response => {
      const { orderIds=[] } = sanitize(response, {});
      if(callback != null) {
        callback(orderIds);
      }
      return orderIds;
    });
};

export const doGetQueue = (n=20, callback=null) => {
  const init = {
    queryStringParameters: { n },
  };
  return API.get("orders", "/recent", init)
    .then(response => {
      const { orders=[] } = sanitize(response, {}); 
      if(callback != null) {
        callback(orders);
      }
    });
};

export const doPostAvailableIngredientCounts = (callback=null) => {
  const init = {
    queryStringParameters: {},
    body: {},
  };
  return API.post("orders", "/orders", init)
    .then(response => {
      const availableIngredientCounts = sanitize(response, {});
      if(callback != null) {
        callback(availableIngredientCounts);
      }
    });
};

/*export const fetchAllOrderStatus = (callback=null) => {
  return doGet("/api/private/orders/status")
    .then(res => res.json())
    .then(orders => {
      if(callback != null) {
        callback(orders);
      }
      return orders;
    });
};*/
