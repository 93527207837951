import { API } from "aws-amplify"
import { sanitize } from "./ApiUtil"

export const doGetInventory = (callback=null) => {
  return API.get("state", "/systemState/inventory")
    .then(res => {
      const inventory = sanitize(res, {});
      if(inventory.cookies == null) {
        inventory.cookies = [...Array(40)];
      }
      if(inventory.dryToppings == null) {
        inventory.dryToppings = [...Array(40)];
      }
      if(inventory.wetToppings == null) {
        inventory.wetToppings = [...Array(2)];
      }
      if(inventory.wetToppingCounts == null) {
        inventory.wetToppingCounts = [...Array(2)];
      }
      if(inventory.flowing == null) {
        inventory.flowing = {
          cookies: {

          },
          dryToppings: {
    
          },
          wetToppings: {
    
          },
        };
      }
      if(callback != null) {
        callback(inventory);
      }
    });
};

export const doPutInventory = (inventory, callback=null) => {
  const init = {
    body: inventory,
  }
  return API.put("state", "/systemState/inventory", init)
    .then(res => {
      if(callback != null) {
        callback(sanitize(res));
      }
    });
};

export const doGetEntry = (callback=null) => {
  return API.get("state", "/systemState/entry")
    .then(res => {
      const status = sanitize(res, {});
      if(callback != null) {
        callback(status);
      }
    });
};
