/* eslint eqeqeq: "off" */
import React from "react"
import queryString from "query-string"
import Amplify from "aws-amplify"
import { isClient } from "./EnvironmentUtil"
import awsconfig from "../aws-exports"
import { doGetOrder, doPutOrder, doGenerateOrderId, doGetQueue, doPostAvailableIngredientCounts } from "../api/OrdersApi"
import { doGetInventory, doPutInventory, doGetEntry } from "../api/StateApi"
import QueriedImage from "../ui/QueriedImage"
import COOKIE_DATAS from "../data/content/cookies.json"
import DRY_TOPPING_DATAS from "../data/content/dry-toppings.json"
import WET_TOPPING_DATAS from "../data/content/wet-toppings.json"

export const WET_TOPPING_LIMIT = 200;

const toToppingKey = topping => (topping == null || topping.name == null) ? topping : topping.name;

const COOKIE_DATAS_BY_NAME = {};
for(let d of COOKIE_DATAS) {
  COOKIE_DATAS_BY_NAME[d.name] = d;
}
const COOKIE_NAMES = COOKIE_DATAS.map(c => c.name);
export const getCookieNames = () => COOKIE_NAMES;
export const getCookieDatas = () => COOKIE_DATAS;
const getCookieData = cookie => COOKIE_DATAS_BY_NAME[cookie];

const DRY_TOPPING_DATAS_BY_NAME = {};
for(let d of DRY_TOPPING_DATAS) {
  DRY_TOPPING_DATAS_BY_NAME[toToppingKey(d)] = d;
}
const DRY_TOPPING_NAMES = DRY_TOPPING_DATAS.map(t => t.name);
export const getDryToppingNames = () => DRY_TOPPING_NAMES;
export const getDryToppingDatas = () => DRY_TOPPING_DATAS;
const getDryToppingData = topping => DRY_TOPPING_DATAS_BY_NAME[toToppingKey(topping)];

const WET_TOPPING_DATAS_BY_NAME = {};
for(let d of WET_TOPPING_DATAS) {
  WET_TOPPING_DATAS_BY_NAME[toToppingKey(d)] = d;
}
const WET_TOPPING_NAMES = WET_TOPPING_DATAS.map(t => t.name);
export const getWetToppingNames = () => WET_TOPPING_NAMES;
export const getWetToppingDatas = () => WET_TOPPING_DATAS;
const getWetToppingData = topping => WET_TOPPING_DATAS_BY_NAME[toToppingKey(topping)];

export const getToppingDatas = type => type == "dry" ? getDryToppingDatas() : getWetToppingDatas();
export const getOrderToppings = (order, type) => type == "dry" ? order.dryToppings : order.wetToppings;

export const getData = name => {
  let data = getCookieData(name);
  if(data != null) {
    return data;
  }
  data = getDryToppingData(name);
  if(data != null) {
    return data;
  }
  data = getWetToppingData(name);
  if(data != null) {
    return data;
  }
  return null;
};

export const getLabel = name => {
  const data = getData(name);
  if(data == null) {
    return name;
  }
  return data.label;
};

export const configureApis = () => {
  Amplify.configure(awsconfig);
};

export const formatOrderId = orderId => {
  orderId = "" + orderId;
  if(orderId.length <= 4) {
    return orderId;
  }
  return orderId.substr(0,4).toUpperCase();
};

export const fromUrlToOrderId = url => {
  if(url != null) {
    const query = url.split("?")[1];
    const { orderId=null } = queryString.parse(query);
    return orderId;
  }
  return null;
};

export const fromOrderIdToUrl = orderId => {
  let baseUrl;
  if(isClient()) {
    baseUrl = window.location.href;
  } else {
    baseUrl = "localhost:8000/order-qr-writer/";
  }

  let url;
  if(baseUrl.includes("order-qr-sheet-writer")) {
    url = baseUrl.replace("order-qr-sheet-writer", "order-url-reader");
  } else if(baseUrl.includes("order-qr-zip-writer")) {
    url = baseUrl.replace("order-qr-zip-writer", "order-url-reader");
  } else {
    url = baseUrl.replace("order-qr-writer", "order-url-reader");
  }

  // We need to end with a slash before our "?" arguments or else they 
  // won't be parsed properly
  if(!url.endsWith("/")) {
    url = url + "/";
  }
  url = url + "?orderId=" + orderId;

  return url;
};

export const isToppingSelected = (toppings, topping) => {
  return toppings.find(t => t.name == topping.name);
};

export const isToppingExtra = (toppings, toppingData) => {
  const topping = toppings.find(t => t.name == toppingData.name);
  if(topping == null) {
    return false;
  }
  return topping.extra;
};

export const createOrder = (orderId=null) => ({
  orderId,
  locationId: null,
  customerName: null,
  cookie: null,
  dryToppings: [],
  wetToppings: [],
  generatedOrderIdAt: null,
  submittedOrderAt: null,
  startedCookingAt: null,
  finishedCookingAt: null,
  pickupSlot: null,
  printed: false,
});

// XXX Implement this to "mock" sanitize our order
export const isSanitaryOrder = order => order != null;

const getCookieImage = (cookieName, props={}) => {
  const data = getCookieData(cookieName);
  if(data != null) {
    return <QueriedImage name={data.name} {...props} />;
  }
  return null;
};

const getDryToppingImage = (toppingName, props={}) => {
  const data = getDryToppingData(toppingName);
  if(data != null) {
    return <QueriedImage name={data.name} {...props} />;
  }
  return null;
};

const getWetToppingImage = (toppingName, props={}) => {
  const data = getWetToppingData(toppingName);
  if(data != null) {
    return <QueriedImage name={data.name} {...props} />;
  }
  return null;
};

export const getImage = (cookieOrToppingName, props={}) => {
  let image = getCookieImage(cookieOrToppingName, props);
  if(image != null) {
    return image;
  }

  image = getDryToppingImage(cookieOrToppingName, props);
  if(image != null) {
    return image;
  }

  image = getWetToppingImage(cookieOrToppingName, props);
  if(image != null) {
    return image;
  }

  return null;
};

export const toDisplaySlot = pickupSlot => pickupSlot + 1

export const isFlowing = (inventory, type, name) => {
  const { flowing=null } = inventory;
  if(flowing == null || flowing[type] == null) {
    return false;
  }
  return flowing[type][name];
};

export const pruneInvalidOrders = orders => {
  return orders.filter(ord => ord != null).filter(ord => {
    const { cookie, dryToppings, wetToppings } = ord;
    if(getCookieData(cookie) == null) {
      return false;
    }
    for(let topping of dryToppings) {
      if(getDryToppingData(topping) == null) {
        return false;
      }
    }
    for(let topping of wetToppings) {
      if(getWetToppingData(topping) == null) {
        return false;
      }
    }
    return true;
  });
};

export const isAvailable = (availableIngredientCounts, type, name) => {
  if(availableIngredientCounts == null) {
    return true;
  }
  if(availableIngredientCounts[type] == null) {
    return true;
  }
  const count = availableIngredientCounts[type][name]; 
  return count != null && count > 0;
}

export const isExtraAvailable = (availableIngredientCounts, type, name) => {
  if(availableIngredientCounts == null) {
    return true;
  }
  if(availableIngredientCounts[type] == null) {
    return true;
  }
  const count = availableIngredientCounts[type][name]; 
  return count != null && count > 1;
}

// API Methods

export const fetchOrder = ({ orderId, onSuccess, onFailure }) => {
  if(orderId != null && orderId.length > 0) {
    doGetOrder(orderId, order => {
      if(order != null) {
        onSuccess(order);
      } else {
        onFailure(order);
      }
    });
  } else {
    onFailure(null);
  }
};

export const submitOrder = (order, callback=null) => {
  return doPutOrder(order, ({ accepted, unavailable=false, invalidName=false }) => {
    if(accepted) {
      fetchOrder({
        orderId: order.orderId,
        onSuccess: fetchedOrder => {
          if(callback != null) {
            callback(fetchedOrder, accepted, unavailable, invalidName);
          }
        },
      })
    } else {
      if(callback != null) {
        callback(null, accepted, unavailable, invalidName);
      }
    }
  });
};

export const createOrderId = (callback=null) => {
  return doGenerateOrderId(1, orderIds => {
    if(callback != null) {
      if(orderIds == null || orderIds.length == 0) {
        callback(null);
      } else {
        callback(orderIds[0]);
      }
    }
  });
};

export const fetchAllOrderStatus = (callback=null) => {
  return doGetQueue(20, callback);
};

export const fetchInventory = (callback=null) => {
  return doGetInventory(callback);
};

export const submitInventory = (inventory, callback=null) => {
  // Add additional checks to make sure our arrays are the right size,
  // just in case they get off for any reason.
  if(inventory.cookies.length > 40) {
    inventory = {...inventory, cookies: inventory.cookies.slice(0, 40)};
  }
  if(inventory.dryToppings.length > 40) {
    inventory = {...inventory, dryToppings: inventory.dryToppings.slice(0, 40)};
  }
  if(inventory.wetToppings.length > 2) {
    inventory = {...inventory, wetToppings: inventory.wetToppings.slice(0, 2)};
  }
  if(inventory.wetToppingCounts.length > 2) {
    inventory = {...inventory, wetToppingCounts: inventory.wetToppingCounts.slice(0, 2)};
  }
  
  return doPutInventory(inventory, callback);
};

export const fetchAvailableIngredientCounts = (callback=null) => {
  return doPostAvailableIngredientCounts(callback);
};

export const requestEntry = (callback=null) => {
  return doGetEntry(callback);
};