import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { store, persistor } from "./src/state/createStore"
import { configureApis } from "./src/util/OrderUtil"

// This hooks up our AWS Amplify Apis and is a one-time call
configureApis();

// eslint-disable-next-line react/display-name,react/prop-types
const wrapper = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  );
};

export default wrapper;
