import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const QueriedImage = ({ name, ...otherProps }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: {ext: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: {eq: "images"}}
        sort: {fields: base, order: ASC}
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
              )
            }
          }
      	}
      }
    }
  `);

  const imageNode = useMemo(
    () => data.images.edges.find(({ node }) => node.base.startsWith(name + ".")),
    [data, name],
  );

  if(!imageNode) return null;

  const imageData = getImage(imageNode.node);
  return <GatsbyImage image={imageData} alt={name} {...otherProps} />
};

export default QueriedImage;

/*export const imageQuery =
`*/
